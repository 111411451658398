/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to true if you want to use the splash screen.
  useCustomCursor: true, // Change this to false if you want the good'ol cursor
  googleTrackingID: "G-GC94HDTH10",
};

//Home Page
const greeting = {
  title: "Hey there! 👋",
  title2: "Vijay",
  logo_name: "svijayb.dev()",
  nickname: "vijay / 3fbc",
  full_name: "S Vijay Balaji",
  subTitle:
    "A Data Science aspirant and a tech enthusiast. I live by the words of Gabrielle Bernstein, “Allow your passion to become your purpose, and it will one day become your profession.”✨",
  resumeLink:
    "https://drive.google.com/file/d/1IO8xmV9WL_gN5Ibn9UyBy5c5kce0qM1z/view",
  mail: "mailto:svijayb.dev@gmail.com",
};

const socialMediaLinks = {
  /* Your Social Media Link */
  github: "https://github.com/svijayb",
  linkedin: "https://www.linkedin.com/in/svijayb/",
  gmail: "svijayb.dev@gmail.com",
  twitter: "https://twitter.com/VijaybalajiS1",
  instagram: "https://www.instagram.com/s.vj._/",
  hackerrank: "https://www.hackerrank.com/profile/svijayb_dev",
};

const skills = {
  data: [
    {
      title: "Data Science & AI",
      fileName: "DataScienceImg",
      skills: [
        "⚡ Building efficient, high-performance models for various deep learning and statistical tasks.",
        "⚡ Proficient in advanced Computer Vision and Natural Language Processing (NLP).",
        "⚡ Expertise in sophisticated quantitative modeling for predictive forecasting and time series analysis.",
      ],
      softwareSkills: [
        {
          skillName: "Tensorflow",
          fontAwesomeClassname: "logos-tensorflow",
        },
        {
          skillName: "Keras",
          fontAwesomeClassname: "devicon:keras",
        },
        {
          skillName: "PyTorch",
          fontAwesomeClassname: "devicon:pytorch",
        },
        {
          skillName: "Scikit-learn",
          fontAwesomeClassname: "devicon:scikitlearn",
        },
        {
          skillName: "Pandas",
          fontAwesomeClassname: "logos:pandas",
        },
        {
          skillName: "NumPy",
          fontAwesomeClassname: "logos-numpy",
        },
        {
          skillName: "Apache Spark",
          fontAwesomeClassname: "logos:apache-spark",
        },
        {
          skillName: "Power BI",
          fontAwesomeClassname: "logos:microsoft-power-bi",
        },
        {
          skillName: "Tableau",
          fontAwesomeClassname: "logos:tableau-icon",
        },
        {
          skillName: "Matplotlib",
          fontAwesomeClassname: "devicon:matplotlib",
        },
        {
          skillName: "OpenCV",
          fontAwesomeClassname: "logos:opencv",
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "logos-python",
        },
        {
          skillName: "Jupyter",
          fontAwesomeClassname: "logos:jupyter",
        },
      ],
    },
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Develop robust and scalable backend applications using Flask, Django, and Node.js",
        "⚡ Design and implement RESTful and GraphQL APIs for seamless client-server communication",
        "⚡ Enhance database performance and integrity through Redis and caching strategies, alongside diverse database utilization.",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "devicon:html5",
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "devicon:css3",
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "logos:javascript",
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "logos:nodejs",
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "logos-python",
        },
        {
          skillName: "Django",
          fontAwesomeClassname: "simple-icons:django",
          style: {
            color: "#44B78B",
          },
        },
        {
          skillName: "Flask",
          fontAwesomeClassname: "logos:flask",
        },
        {
          skillName: "Java",
          fontAwesomeClassname: "devicon:java",
        },
        {
          skillName: "C++",
          fontAwesomeClassname: "devicon:cplusplus",
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "logos:postgresql",
        },
        {
          skillName: "MySQL",
          fontAwesomeClassname: "logos:mysql",
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "GraphQL",
          fontAwesomeClassname: "logos:graphql",
        },
        {
          skillName: "Redis",
          fontAwesomeClassname: "devicon:redis-wordmark",
        },
        {
          skillName: "Git",
          fontAwesomeClassname: "devicon:git",
        },
      ],
    },
    {
      title: "Cloud Infra-Architecture",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Experience in setting up CI/CD pipelines for seamless deployment workflows",
        "⚡ Engaged in infrastructure automation through Ansible playbooks, streamlining server management processes.",
        "⚡ Leveraged Terraform for infrastructure-as-code practices, automating tasks critical for disaster recovery.",
        "⚡ Led migration initiatives from traditional VM hosting to Kubernetes, optimizing application scalability and resource efficiency.",
      ],
      softwareSkills: [
        {
          skillName: "Azure",
          fontAwesomeClassname: "devicon:azure",
        },
        {
          skillName: "AWS",
          fontAwesomeClassname: "logos:aws",
        },
        {
          skillName: "Azure DevOps",
          fontAwesomeClassname: "devicon:azuredevops",
        },
        {
          skillName: "GitHub Actions",
          fontAwesomeClassname: "devicon:githubactions",
        },
        {
          skillName: "Jenkins",
          fontAwesomeClassname: "logos:jenkins",
        },
        {
          skillName: "Netlify",
          fontAwesomeClassname: "devicon:netlify",
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "logos:firebase",
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "logos:docker-icon",
        },
        {
          skillName: "Kubernetes",
          fontAwesomeClassname: "logos:kubernetes",
        },
        {
          skillName: "Terraform",
          fontAwesomeClassname: "devicon:terraform",
        },
        {
          skillName: "Ansible",
          fontAwesomeClassname: "logos:ansible",
        },
        {
          skillName: "Prometheus",
          fontAwesomeClassname: "logos:prometheus",
        },
        {
          skillName: "Grafana",
          fontAwesomeClassname: "logos:grafana",
        },
        {
          skillName: "Kibana",
          fontAwesomeClassname: "logos:kibana",
        },
      ],
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "University of Washington, Seattle",
      subtitle: "Master of Science in Data Science",
      logo_path: "UW.png",
      alt_name: "UW",
      duration: "2024 - Present",
      descriptions: [
        "⚡ Starting a Master's program focused on DS methodologies and their real-world applications. ",
        "⚡ Excited to engage in cutting-edge research and innovative projects at the university.",
        "⚡ According to US news, UW ranks #6 in Computer Science (AI), #7 in Statistics, and #3 in Biostatistics",
        "⚡ Not so subtle flex: One among the 60 admits out of 1200+ applications (5% admit ratio)",
      ],
      website_link: "https://www.washington.edu/",
    },
    {
      title: "Vellore Institute of Technology (VIT-AP)",
      subtitle: "Bachelor of Technology in Computer Science Engineering",
      logo_path: "VIT.png",
      alt_name: "VIT",
      duration: "2019 - 2023",
      descriptions: [
        "⚡ Took up various Data Science electives and earned a Big Data Analytics certification.",
        "⚡ Promoted to lead a club, held several solo webinars on topics covering Git, GitHub and Linux",
        "⚡ Conducted and published patented research in the Mathematics journal.",
        "⚡ Graduated with a CGPA of 9.3.",
      ],
      website_link: "https://www.vitap.ac.in/",
    },
    {
      title: "Narayana PU/CBSE College",
      subtitle: "Senior Secondary High School",
      logo_path: "narayana.png",
      alt_name: "Narayana",
      duration: "2017 - 2019",
      descriptions: [
        "⚡ Studied PCMC, scoring 96% in junior year and 87% in senior year in Computer science. ",
        "⚡ Learnt C++, MySQL and other core programming concepts like Data Structures and OOPS here.",
      ],
      website_link: "https://www.narayanagroup.com/",
    },
    {
      title: "Geethanjali Vidyalaya",
      subtitle: "Primary and High School",
      logo_path: "GV.png",
      alt_name: "GV",
      duration: "2006 - 2017",
      descriptions: [
        "⚡ Led the science club, fostering scientific curiosity, with an emphasis on computer science.",
        "⚡ Participated in several competitions, securing runner-up in ISRO extempore and earning the Best Actor award at Times NIE playfest.",
        "⚡ Secured a perfect 10 CGPA, demonstrating academic excellence and setting a strong foundation for future studies",
      ],
      website_link: "https://www.washington.edu/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Azure Fundamentals",
      subtitle: "Microsoft",
      logo_path: "azure.png",
      certificate_link:
        "https://drive.google.com/file/d/1n0lCdQewo-Rht34aup1jbtJ1Lkxrj7_C/view?usp=sharing",
      alt_name: "Azure Fundamentals",
      color_code: "#E0F7FF",
    },
    {
      title: "Big Data Foundation",
      subtitle: "Nasscom",
      logo_path: "nasscom.png",
      certificate_link:
        "https://drive.google.com/file/d/1-z7L7Zti6cgBQdpY4Fp3ybK4KoWhaJt8/view?usp=sharing",
      alt_name: "Nasscom",
      color_code: "#FFE6E1",
    },
    {
      title: "Agile Scrum Master",
      subtitle: "Udemy Certification",
      logo_path: "udemy.png",
      certificate_link:
        "https://drive.google.com/file/d/1WUajtwb7X2wPkYsQEqfu2MdoSD259ngt/view?usp=sharing",
      alt_name: "Udemy",
      color_code: "#F3E6FF",
    },
    {
      title: "Runner-up at Hackathon",
      subtitle: "NMIT Hacks 2022",
      logo_path: "nmit.png",
      certificate_link:
        "https://drive.google.com/file/d/1psjmVkFbRxHnE1OpIHl-707YPIp08ZYe/view?usp=sharing",
      alt_name: "NMIT Hacks",
      color_code: "#FFDAB9",
    },
    {
      title: "WSoC 2020 Mentor",
      subtitle: "Open Source Community",
      logo_path: "wsoc.png",
      certificate_link:
        "https://drive.google.com/file/d/1iT_FbL2uXm532b8ubyl43iK1HqgO49IV/view?usp=sharing",
      alt_name: "WSoC",
      color_code: "#89CFF0",
    },
    {
      title: "Academic Support Volunteer",
      subtitle: "Make A Difference (MAD)",
      logo_path: "mad-logo.png",
      certificate_link:
        "https://drive.google.com/file/d/1i60WBuSdubQpKxGn8L-JFcvg2zhgNmOw/view?usp=sharing",
      alt_name: "MAD",
      color_code: "#FFCCCC",
    },
    {
      title: "Penetration Testing",
      subtitle: "PlugXR",
      logo_path: "plugxr.png",
      certificate_link:
        "https://drive.google.com/file/d/1oKFSH2QQ-eAXHQFiTSR8EGpCcUQlGNXN/view?usp=sharing",
      alt_name: "plugxr",
      color_code: "#FFFDD0",
    },
    {
      title: "Rest API (Intermediate)",
      subtitle: "HackerRank Certification",
      logo_path: "hackerrank.png",
      certificate_link: "https://www.hackerrank.com/certificates/66ed64783462",
      alt_name: "hackerrank",
      color_code: "#AFEEEE",
    },
    {
      title: "MLH Local Hack Day: Build",
      subtitle: "Major League Hacking",
      logo_path: "mlh-logo.png",
      certificate_link:
        "https://drive.google.com/file/d/1LkhUcf8DiU3nX6nIuLjX5Ky9HU6ql99Y/view?usp=sharing",
      alt_name: "hackerrank",
      color_code: "#E8F8F5",
    },
    {
      title: "Extempore Science Elocution",
      subtitle: "Indian Space Research Organisation",
      logo_path: "isro.png",
      certificate_link:
        "https://drive.google.com/file/d/1TjsdoNfH5PkfSD9J1SCMsYstLN3T549T/view?usp=sharing",
      alt_name: "isro",
      color_code: "#E6E6FA",
    },
    {
      title: "Times NIE Playfest",
      subtitle: "The Times of India",
      logo_path: "toi-nie.png",
      certificate_link:
        "https://drive.google.com/file/d/1SIxNfC5WbQCAwDCB90xwCbqjMhhy8ZoH/view?usp=sharing",
      alt_name: "nie-playfest",
      color_code: "#F5F5F5",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description: `I have experiences from various domains of Computer science, including SWE, DevOps, ML and DL.
    Passionate about open-source, I've fostered communities, organized events, and mentored teams, ensuring impactful contributions throughout.`,
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work Experience",
      experiences: [
        {
          title: "DevOps Engineer",
          company: "Fareportal",
          company_url: "https://fareportal.com/",
          logo_path: "fareportal.png",
          duration: "Oct 2022 - Present",
          location: "Gurugram, Haryana",
          description: `Set up CI/CD pipelines on Azure DevOps for seamless deployment workflows.
                        Wrote Ansible playbooks to automate several aspects of server infrastructure.
                        Assisted in migrating VM applications to Kubernetes.
                        Worked with ActiveBatch for application automation and scheduling.
                        Built projects for automating tasks during disaster recovery using Terraform.`,
        },
        {
          title: "Technical Content Writer (Internship)",
          company: "Tutorialspoint",
          company_url: "https://www.tutorialspoint.com/",
          logo_path: "tutorialspoint.png",
          duration: "Mar 2021 - Jul 2021",
          location: "WFH",
          description: `Interned at Tutorialspoint as a technical content writer,
                        created and submitted over 200 detailed articles on Python and its libraries.
                        Provided comprehensive answers to community queries through well-defined articles in the Q&A section.`,
        },
        {
          title: "Deep Learning Intern",
          company: "Artificial Intelligence and Robotic (AIR) Center VIT-AP",
          company_url: "https://www.vitap.ac.in/ai-robotics-center",
          logo_path: "VIT.png",
          duration: "Aug 2020 - Sep 2020",
          location: "WFH",
          description: `Worked on image classification, object detection, and various machine learning image recognition models, specifically the InceptionV3 model.
                        Deployed trained models to the cloud for easier accessibility
                        Developed a fully functional, production-ready product capable of identifying retail products with 97% accuracy.`,
        },
      ],
    },
    {
      title: "Volunteerships",
      experiences: [
        {
          title: "Community Lead",
          company: "Open Source Community VIT-AP",
          company_url: "https://oscvitap.org/",
          logo_path: "osc.png",
          duration: "Sep 2019 - May 2022",
          location: "Amaravati",
          description: `Joined the Open Source Community as a freshman, quickly becoming PR head and organizing major events like MLH Build 2019 and Software Freedom Day 2020.
            Advanced to Vice President role in sophomore year, held several solo webinars on Git, GitHub and Linux, led the Winter Season of Code initiative.
            Was later Promoted to Community Leader in my junior year, oversaw a team of 20+ members, organized impactful FOSS events, and contributing to several tech projects.`,
        },
        {
          title: "Mozilla Campus Captain",
          company: "Mozilla",
          company_url: "https://community.mozilla.org/en/",
          logo_path: "mozilla.png",
          duration: "May 2021 - May 2022",
          location: "Amaravati",
          description: `My role involved establishing an open-source culture within our college. 
            We organized numerous events promoting Free and Open Source Software (FOSS), 
            including hackathons focused on challenges sourced from the local community. 
            Our efforts led to a thriving open-source community within the college campus.`,
        },
        {
          title: "Academic Support Volunteer",
          company: "Make A Difference (MAD)",
          company_url: "https://makeadiff.in/",
          logo_path: "mad.png",
          duration: "Nov 2021 - May 2022",
          location: "Bangalore",
          description: `Provided academic support for students from 5th grade to 10th grade. 
            Took sessions/classes on the Science curriculum. 
            My main objective was to provide a strong knowledge base for the children in order for them to have a more productive and stable future.`,
        },
        {
          title: "Winter Season of Code Mentor",
          company: "Open Source Community",
          company_url: "https://wsoc.oscvitap.org/",
          logo_path: "wsoc-icon.png",
          duration: "Dec 2020 - Feb 2021",
          location: "Amaravati",
          description: `Mentored a team of 4 members for the WSoC initiative by OSC VIT-AP. 
          Developed a Productivity Monitoring Tool using Python and Django, integrating HTML, CSS, and Bootstrap. 
          The tool tracks desktop app usage, provides detailed user activity analysis, and 
          includes features to block apps/websites, improving user focus during work.`,
        },
        {
          title: "Developer Program Member",
          company: "GitHub",
          company_url: "https://github.com/svijayb",
          logo_path: "github.png",
          duration: "Sep 2019 - Present",
          location: "WFH",
          description: `I actively contribute to multiple open-source projects and maintain repositories that provide 
          foundational learning materials for programming languages, ranging from basic to advanced levels. 
          These repositories include comprehensive documentation and code examples for practical learning.
          I have also made substantial contributions to organizations and projects, including bug fixes, feature requests, and detailed documentation.`,
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects utilize a wide array of cutting-edge tools and technologies, typically focusing on data science, automation scripts, APIs, and microservices.",
  avatar_image_path: "projects_image.svg",
};

const projects = {
  data: [
    {
      id: "1",
      name: "Gist",
      url: "https://github.com/SVijayB/Gist",
      description:
        "An abstractive style text summarizer that emphasizes on news summarization and classification. Won runner up as part of NMIT Hacks 2022.",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos:python",
        },
        {
          name: "Flask",
          iconifyClass: "logos:flask",
        },
        {
          name: "HuggingFace Transformers",
          iconifyClass: "logos:hugging-face-icon",
        },
        {
          name: "MongoDB",
          iconifyClass: "logos:mongodb-icon",
        },
        {
          name: "ReactJS",
          iconifyClass: "logos-react",
        },
      ],
      links: [
        {
          name: "Github",
          url: "https://github.com/SVijayB/Gist",
          iconifyClass: "ri:github-fill",
        },
      ],
    },
    {
      id: "2",
      name: "DocCompare",
      url: "https://www.mdpi.com/1944880",
      description:
        "Developed a patented solution to prevent character injection in document similarity algorithms using k-grams. Published a paper in the Mathematics journal.",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos:python",
        },
        {
          name: "NumPy",
          iconifyClass: "logos:numpy",
        },
        {
          name: "OpenCV",
          iconifyClass: "logos:opencv",
        },
        {
          name: "Django",
          iconifyClass: "simple-icons:django",
        },
        {
          name: "ReactJS",
          iconifyClass: "logos-react",
        },
      ],
      links: [
        {
          name: "Publication",
          url: "https://www.mdpi.com/1944880",
          iconifyClass: "jam:write-f",
        },
        {
          name: "Front-end",
          url: "https://doccompare-test.netlify.app/",
          iconifyClass: "mdi:web",
        },
      ],
    },
    {
      id: "3",
      name: "Productivity Tracker",
      url: "https://github.com/kevalvavaliya/Adoptisity",
      description:
        "An analytics tool that tracks user activity on desktops, offering detailed insights into app usage and time spent, leading to increased productivity",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos:python",
        },
        {
          name: "Django",
          iconifyClass: "simple-icons:django",
        },
        {
          name: "HTML5",
          iconifyClass: "logos-html-5",
        },
        {
          name: "CSS3",
          iconifyClass: "logos-css-3",
        },
      ],
      links: [
        {
          name: "Github",
          url: "https://github.com/osc-vitap/Productivity-tracker",
          iconifyClass: "ri:github-fill",
        },
      ],
    },
    {
      id: "4",
      name: "Terraform-DR-MSSQL-Backup",
      url: "https://github.com/SVijayB/Terraform-scripts",
      description:
        "Terraform project that automates data restoration from Azure Blob Storage to a newly provisioned MSSQL VM, designed for disaster recovery scenarios.",
      languages: [
        {
          name: "Terraform",
          iconifyClass: "devicon:terraform",
        },
        {
          name: "Azure",
          iconifyClass: "devicon:azure",
        },
        {
          name: "PowerShell",
          iconifyClass: "devicon:powershell",
        },
        {
          name: "Azure DevOps",
          iconifyClass: "devicon:azuredevops",
        },
      ],
      links: [
        {
          name: "Github",
          url: "https://github.com/SVijayB/Terraform-scripts",
          iconifyClass: "ri:github-fill",
        },
      ],
    },
    {
      id: "5",
      name: "OSC-API",
      url: "https://github.com/osc-vitap/OSC-API",
      description:
        "Built a centralized API for the open-source community, serving all the required content for their main website, automating event notifications via Discord bot, and more!",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos:python",
        },
        {
          name: "Flask",
          iconifyClass: "logos:flask",
        },
        {
          name: "MongoDB",
          iconifyClass: "logos:mongodb-icon",
        },
      ],
      links: [
        {
          name: "Github",
          url: "https://github.com/osc-vitap/OSC-API",
          iconifyClass: "ri:github-fill",
        },
      ],
    },
    {
      id: "6",
      name: "OSC-DBot",
      url: "https://github.com/osc-vitap/OSC-DBot",
      description:
        "Built a Discord bot for the open source community to increase server engagement with discussion prompts and mini-games, and also to automate event announcements.",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos:python",
        },
        {
          name: "Discord",
          iconifyClass: "logos:discord-icon",
        },
      ],
      links: [
        {
          name: "Github",
          url: "https://github.com/osc-vitap/OSC-DBot",
          iconifyClass: "ri:github-fill",
        },
      ],
    },
    {
      id: "7",
      name: "NPM-package-updater",
      url: "https://github.com/SVijayB/NPM-package-updater",
      description:
        "Given a list of Node.js GitHub repositories, the app checks if a specified dependency meets the provided version. If it's lower, users can opt to update it through automated pull requests.",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos:python",
        },
        {
          name: "Flask",
          iconifyClass: "logos:flask",
        },
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
      ],
      links: [
        {
          name: "Github",
          url: "https://github.com/SVijayB/NPM-package-updater",
          iconifyClass: "ri:github-fill",
        },
      ],
    },
    {
      id: "8",
      name: "Custom-notepad",
      url: "https://github.com/SVijayB/Custom-notepad",
      description:
        "Built a custom notepad to overcome the default one’s feature limitations. It includes advanced features like dark mode, temporary data saving, text formatting, and more!",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos:python",
        },
      ],
      links: [
        {
          name: "Github",
          url: "https://github.com/SVijayB/Custom-notepad",
          iconifyClass: "ri:github-fill",
        },
        {
          name: "Releases",
          url: "https://github.com/SVijayB/Custom-notepad/releases/tag/v1.0.0",
          iconifyClass: "hugeicons:package-sent",
        },
      ],
    },
    {
      id: "9",
      name: "Lock & Unlock PC using RFID",
      url: "https://github.com/SVijayB/Lock_Unlock_A_Computer_Using_RFID",
      description:
        "A compact Arduino and C project that utilizes an RFID card to lock and unlock computers, simplifying password entry and facilitating the use of complex passwords effortlessly.",
      languages: [
        {
          name: "Arduino",
          iconifyClass: "logos:arduino",
        },
        {
          name: "RFID",
          iconifyClass: "ri:rfid-line",
        },
        {
          name: "C",
          iconifyClass: "hugeicons:c-programming",
        },
      ],
      links: [
        {
          name: "Github",
          url: "https://github.com/SVijayB/Lock_Unlock_A_Computer_Using_RFID",
          iconifyClass: "ri:github-fill",
        },
      ],
    },
    {
      id: "10",
      name: "Steam WebScraper",
      url: "https://github.com/SVijayB/Steam_WebScraper",
      description:
        "This app monitors Steam market values of CSGO items using web scraping. It alerts users when prices drop below a set threshold, saving money and time.",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
      ],
      links: [
        {
          name: "Github",
          url: "https://github.com/SVijayB/Steam_WebScraper",
          iconifyClass: "ri:github-fill",
        },
        {
          name: "Releases",
          url: "https://github.com/SVijayB/Steam_WebScraper/releases",
          iconifyClass: "hugeicons:package-sent",
        },
      ],
    },
  ],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "vijay.jpeg",
    description:
      "You can contact me through any of the social links below. I'll try to get back to you as soon as possible!",
  },
  blogSection: {
    title: "Blogs",
    subtitle: `I write blogs on a wide range of topics, including life events, academic and project experiences, life principles, and general rants.`,
    link: "https://blog.vijaybalaji.com/",
    avatar_image_path: "blogs_image.svg",
  },
};

export {
  settings,
  greeting,
  socialMediaLinks,
  skills,
  degrees,
  certifications,
  experience,
  projectsHeader,
  contactPageData,
  projects,
};
